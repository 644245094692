import React from 'react'

import { Col } from 'antd'
import { FormInput, FormItem, FormSelect, Table } from 'yolo-design/components'
import {
  convertKgToMtr,
  getNoOfPouches,
  getPouchPerKg,
  getPouchWeight,
} from '@utils/formula'
import { useSearchParams } from 'react-router-dom'
import {
  useDFormKeyValue,
  useDFormOptionList,
  useDFormTypeWorkId,
  useDFormWorkId,
  useDStepsId,
  useDWorkOrder,
} from '@services/dynamic'
import { restrictToNumbersAndDots } from 'yolo-design/utils'

const Preference = () => {
  const [searchParams] = useSearchParams()

  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''
  const view = searchParams.get('view') || 'add'

  const { data: job } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const { data: workData, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const data =
    !!workId && workLoading
      ? {}
      : workData?.is_completed
      ? workData?.job_data
      : job

  const formTypeId = useDFormTypeWorkId()
  const workFormId = useDFormWorkId(formTypeId) || ''
  const stepsId = useDStepsId({ formId: workFormId }) || ''
  const stepOptions = useDFormOptionList(stepsId)

  const totalGSM = data?.bill_of_materials?.total_thickness_gsm
  const flimWidth = data?.cylinder_details?.total_printing_width
  const processData = data?.process_and_details

  const completedDate = workData?.completed_date
  const pouchWeight = Number(
    getPouchWeight({
      gsm: data?.bill_of_materials?.total_thickness_gsm,
      job_height: data?.cylinder_details?.job_height,
      job_width: data?.cylinder_details?.job_width,
      repeat: data?.cylinder_details?.no_of_repeat,
    })
  )

  return (
    <Col span={7}>
      <div className="d-flex h-100 flex-column border-start border-grey-200 px-24 py-24">
        {!!workData?.is_completed && (
          <>
            <span className="text-title-medium fw-semibold text-dark mb-12">
              Completed Date
            </span>
            <span className="text-title-small text-dark mb-20 pb-12 border-bottom border-grey-300">
              {completedDate
                ? new Date(completedDate).toLocaleString('en-IN')
                : '-'}
            </span>
          </>
        )}
        <FormInput
          required
          readOnly
          title="Work Order No."
          name={'work_order_number'}
        />
        <FormSelect
          required
          disabled={view === 'view'}
          name={'steps'}
          title={'Steps'}
          antdSelectProps={{ mode: 'multiple' }}
          andtFormItemProps={{ className: 'w-100' }}
          placeHolder="Select Steps"
          optionsSelect={stepOptions || []}
        />
        <FormInput
          required
          readOnly={view === 'view'}
          title="Output Quantity"
          name={'output_quantity'}
          optionalTitle="Kg"
        />
        <FormInput
          required
          readOnly={view === 'view'}
          title="Wastage (%)"
          name={'wastage'}
          onInput={restrictToNumbersAndDots}
          andtFormItemProps={{ initialValue: 5 }}
        />
        <FormItem noStyle shouldUpdate>
          {(form) => {
            const outputKg = form.getFieldValue('output_quantity')
            const totalWaste = form.getFieldValue('wastage') || 0
            const outputWastageKg = Number(
              Number(outputKg * (totalWaste / 100)).toFixed(2)
            )
            const outputPlusWastageKg =
              Number(outputKg) +
              Number(Number(outputKg * (totalWaste / 100)).toFixed(2))

            return (
              <>
                <Table
                  columns={[
                    { title: '', dataIndex: 'name' },
                    {
                      title: 'KG',
                      dataIndex: 'kg',
                      render: (val) => (val ? `${val} kg` : '-'),
                    },
                    {
                      title: 'METER',
                      dataIndex: 'mtr',
                      render: (val) => (val ? `${val} mtr` : '-'),
                    },
                  ]}
                  rowKey={'name'}
                  dataSource={[
                    {
                      name: 'Output',
                      kg: outputKg,
                      mtr: convertKgToMtr({
                        outputKg,
                        totalGSM,
                        flimWidth,
                      }),
                    },
                    {
                      name: `Wastage (${totalWaste}%)`,
                      kg: outputWastageKg,
                      mtr: convertKgToMtr({
                        outputKg: outputWastageKg,
                        totalGSM,
                        flimWidth,
                      }),
                    },
                    {
                      name: 'Total',
                      kg: outputPlusWastageKg,
                      mtr: convertKgToMtr({
                        outputKg: outputPlusWastageKg,
                        totalGSM,
                        flimWidth,
                      }),
                      highlight: true,
                    },
                  ]}
                />
                {processData?.slit_type?.code === 2 && (
                  <span className="text-label-medium mt-12 fw-medium">
                    No of Pouches:
                    <b>
                      {getNoOfPouches({
                        outputQuantity: outputKg,
                        pouchPerKg: getPouchPerKg(pouchWeight),
                      })}
                    </b>{' '}
                  </span>
                )}
              </>
            )
          }}
        </FormItem>
      </div>
    </Col>
  )
}

export default Preference
