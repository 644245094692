import { PaginatedResponse } from '@contracts/models/shared'
import { DELETE, GET, POST, PUT } from '@lib/AxiosClient'

export const getDispatchList = (
  signal: AbortSignal | undefined,
  params: {
    page: number
    page_size: number
    search?: string
    status?: string
  }
): Promise<PaginatedResponse<any>> => {
  return GET({
    url: `process/dispatch/`,
    signal,
    params: {
      page: params?.page,
      page_size: params?.page_size,
      search: params?.search,
      status: params?.status,
    },
  })
}

export const getWorkJobSearchList = (params: {
  search: string
  page: number
  page_size: number
}) =>
  GET({
    url: 'process/work-order-list/',
    params,
  })

export const postDispatchData = (data: any): Promise<{ message: string }> =>
  POST({
    url: 'process/dispatch/',
    ...data,
  })

export const putDispatchData = ({
  data,
}: {
  data: any
}): Promise<{ message: any }> =>
  PUT({
    url: `process/dispatch/${data?.id}/`,
    data: {
      dispatch_status: data?.dispatch_status,
      dispatch_date: data?.dispatch_date,
    },
  })
export const deleteDispatchData = ({
  data,
}: {
  data: any
}): Promise<{ message: any }> =>
  DELETE({
    url: `process/dispatch/${data?.id}/`,
  })
export const getDisatchDataById = (id: number): Promise<any> =>
  GET({ url: `process/dispatch/${id}/` })

export const deleteDispatchItem = ({
  id,
}: {
  id: any
}): Promise<{ message: string }> =>
  DELETE({
    url: `process/dispatch-item/${id}/`,
  })
