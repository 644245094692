import { queries } from '@constants/queryKeys'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as API from './api'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
  UseQueryType,
} from '@contracts/query'
import {
  IDForm,
  IDFormKey,
  IDFormKeyValue,
  IDFormKeyValueReq,
  IDFormOption,
  IDFormType,
  IFormDetailsByCode,
  IStaticSelectFields,
} from '@contracts/models/dynamic'
import {
  BOM_FORM_KEY_CODE,
  COLOR_FORM_KEY_CODE,
  CYLINDER_FORM_KEY_CODE,
  FORM_CODE,
  FORM_TYPE_CODE,
  JOB_FORM_KEY_CODE,
  PROCESS_FORM_KEY_CODE,
  WORK_FORM_KEY_CODE,
} from '@constants/dynamic'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'
import { PaginatedResponse } from '@contracts/models/shared'

export const useDFormType: UseQueryType<IDFormType[]> = (options) =>
  useQuery({
    queryKey: queries.dynamic.form_type.queryKey,
    queryFn: API.getDFormType,
    ...options,
  })

export const useDFormTypeJobId = () =>
  useDFormType()?.data?.find((f) => f.code === FORM_TYPE_CODE['job'])?.id

export const useDFormTypeWorkId = () =>
  useDFormType()?.data?.find((f) => f.code === FORM_TYPE_CODE['work'])?.id

export const useDForm: UseQueryDataType<IDForm[], { type?: string }> = (
  options,
  { type }
) =>
  useQuery({
    queryKey: queries.dynamic.form(type).queryKey,
    queryFn: () => API.getDForm({ type }),
    ...options,
  })

export const useDFormWorkId = (type: string | undefined = '') =>
  useDForm({ enabled: !!type }, { type })?.data?.find(
    (f) => f.code === FORM_CODE['work']
  )?.id

export const useDFormJobId = (type: string | undefined = '') =>
  useDForm({ enabled: !!type }, { type })?.data?.find(
    (f) => f.code === FORM_CODE['job']
  )?.id

export const useDFormCylinderId = (type: string | undefined = '') =>
  useDForm({ enabled: !!type }, { type })?.data?.find(
    (f) => f.code === FORM_CODE['cylinder']
  )?.id

export const useDFormColorId = (type: string | undefined = '') =>
  useDForm({ enabled: !!type }, { type })?.data?.find(
    (f) => f.code === FORM_CODE['color']
  )?.id

export const useDFormProcessId = (type: string | undefined = '') =>
  useDForm({ enabled: !!type }, { type })?.data?.find(
    (f) => f.code === FORM_CODE['process']
  )?.id

export const useDFormBOMId = (type: string | undefined = '') =>
  useDForm({ enabled: !!type }, { type })?.data?.find(
    (f) => f.code === FORM_CODE['bill-of-materials']
  )?.id

export const useDPrintingTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === BOM_FORM_KEY_CODE['printing_type']
  )?.id

export const useDBOMKeyId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === BOM_FORM_KEY_CODE['bom']
  )?.id

export const useDFormKey: UseQueryDataType<IDFormKey[], { form: string }> = (
  options,
  { form }
) =>
  useQuery({
    queryKey: queries.dynamic.form_key(form).queryKey,
    queryFn: () => API.getDFormKey({ form }),
    enabled: !!form,
    ...options,
  })

export const useDStepsId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === WORK_FORM_KEY_CODE['steps']
  )?.id

export const useDCylinderTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === CYLINDER_FORM_KEY_CODE['cylinder_type']
  )?.id

export const useDPhotoMarkSizeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === CYLINDER_FORM_KEY_CODE['photocell_mark_size']
  )?.id

export const useDPhotoMarkColorId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === CYLINDER_FORM_KEY_CODE['photocell_mark_color']
  )?.id

export const useDPhotoMarkTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === CYLINDER_FORM_KEY_CODE['photocell_mark_type']
  )?.id

export const useDTravelLengthColorId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === CYLINDER_FORM_KEY_CODE['travel_length_color']
  )?.id

export const useDSlitTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.name === 'slit_type'
    //   (f) => f.code === PROCESS_FORM_KEY_CODE['slit_type']
  )?.id

export const useDUnwindingDirectionId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['unwinding_direction']
  )?.id

export const useDPrintingDirectionId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['printing_direction']
  )?.id

export const useDInspectionId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['inspection']
  )?.id

export const useDCoreTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['core_type']
  )?.id

export const useDPouchTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['pouch_type']
  )?.id

export const useDPunchId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['punch']
  )?.id

export const useDSealTypeId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === PROCESS_FORM_KEY_CODE['seal_type']
  )?.id

export const useDFormKeyValueList: UseInfiniteQueryDataType<
  IDFormKeyValue,
  {
    page_size?: number
    formId: string
    search?: string
    customer_id?: string
    work_status?: string
  }
> = (options, { page_size = 10, formId, search, customer_id, work_status }) => {
  return useInfiniteQueryRef<IDFormKeyValue>({
    queryKey: queries.dynamic.form_key_value_list({
      formId,
      search,
      customer_id,
      work_status,
    }).queryKey,
    queryFn: ({ signal, pageParam }) =>
      API.getDFormKeyValueList(
        signal,
        { page: pageParam, page_size, search, customer_id, work_status },
        formId
      ),
    ...options,
  })
}

export const useCreateDFormKeyValue: UseMutationType<
  { message: string },
  {
    data: IDFormKeyValueReq[]
    formId: string
    params?: { parent_id?: string; customer_id?: string }
  }
> = (options) => useMutation(API.postDFormKeyValue, options)

export const useDFormKeyValue: UseQueryDataType<
  IDFormKeyValue,
  { formId: string }
> = (options, { formId }) =>
  useQuery({
    queryKey: queries.dynamic.form_key_value(formId).queryKey,
    queryFn: () => API.getDFormKeyValue({ formId }),
    ...options,
  })

export const useDWorkOrder: UseQueryDataType<
  IDFormKeyValue,
  { formId: string }
> = (options, { formId }) =>
  useQuery({
    queryKey: queries.dynamic.work_order_key_value(formId).queryKey,
    queryFn: () => API.getWorkOrder({ formId }),
    ...options,
  })

export const useUpdateDFormKeyValue: UseMutationType<
  IDFormKeyValue,
  { data: IDFormKeyValueReq[]; formId: string }
> = (options) => useMutation(API.putDFormKeyValue, options)

export const useDFormOptions: UseQueryDataType<
  IDFormOption[],
  { id: string }
> = (options, { id }) =>
  useQuery({
    queryKey: queries.dynamic.form_option(id).queryKey,
    queryFn: () => API.getDFormOptionList({ id }),
    ...options,
  })

export const useDFormOptionList = (id: string) =>
  useDFormOptions({ enabled: !!id }, { id })?.data?.map((el) => ({
    value: String(el.code),
    label: el.name,
  })) || []

export const useDValidateFormKeyValue: UseMutationType<
  { data: any },
  { data: IDFormKeyValueReq[]; params?: { entity?: string } }
> = (options) => useMutation(API.postDFormKeyValueValidate, options)

export const useUpsertDListItem: UseMutationType<
  any,
  {
    data: { id?: string; data: any }
    path: { entityId: string; keyId: string }
  }
> = (options) => useMutation(API.putDUpsertListItem, options)

export const useDeleteDListItem: UseMutationType<any, { id: string }> = (
  options
) => useMutation(API.deleteDListItem, options)

export const useDColorId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === COLOR_FORM_KEY_CODE['colors']
  )?.id

export const useDDesignFileId = ({ formId }: { formId: string }) =>
  useDFormKey({}, { form: formId })?.data?.find(
    (f) => f.code === JOB_FORM_KEY_CODE['design_files']
  )?.id

export const useJobSearchList: UseQueryDataType<
  PaginatedResponse<{ type: string; data: Record<string, any> }>,
  { search?: string; page?: number; page_size?: number }
> = (options, { search = '', page = 1, page_size = 30 }) =>
  useQuery({
    queryKey: queries.dynamic.job_search_list({ search, page, page_size })
      .queryKey,
    queryFn: () => API.getJobSearchList({ search, page, page_size }),
    ...options,
  })

export const useUniqueCode: UseQueryType<any> = (options) =>
  useQuery({
    queryKey: queries.dynamic.get_unique_code.queryKey,
    queryFn: API.getUniqueCode,
    ...options,
  })

export const useChangeOrder: UseMutationType<
  string,
  { id: string; position: number }
> = (options) => useMutation({ mutationFn: API.putChangeOrder, ...options })

export const useFormDetailsByCode: UseQueryDataType<
  IFormDetailsByCode,
  { code: number }
> = (options, { code }) =>
  useQuery({
    queryKey: queries.dynamic.get_form_details_by_code(code).queryKey,
    queryFn: () => API.getFormDetailsByCode(code),
    ...options,
  })

export const useSelectFields: UseQueryDataType<
  IStaticSelectFields[],
  { formId?: string }
> = (options, { formId }) =>
  useQuery({
    queryKey: queries.dynamic.get_static_select_fields({ formId }).queryKey,
    queryFn: () => API.getSelectFields(formId),
    ...options,
  })

export const useCreateSelectOption: UseMutationType<
  any,
  { fieldId: string; data: { name: string } }
> = (options) => useMutation(API.postCreateSelectOption, options)

export const useEditSelectOption: UseMutationType<
  any,
  { fieldId: string; code: number; data: { name: string } }
> = (options) => useMutation(API.putEditSelectOption, options)

export const useDeleteSelectOption: UseMutationType<
  any,
  { fieldId: string; code: number }
> = (options) => useMutation(API.deleteSelectOption, options)

export const usePrinting: UseQueryDataType<
  any,
  {
    workId: string
    jobId: string
    type: string
    printCode: string
    orgId: string
  }
> = (options, { workId, jobId, type, printCode, orgId }) =>
  useQuery({
    queryKey: queries.dynamic.get_print(workId, jobId, type, printCode, orgId)
      .queryKey,
    queryFn: () =>
      API.getPrintingCard({
        workId,
        jobId,
        type,
        printCode,
        orgId,
      }),
    ...options,
  })
