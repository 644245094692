import {
  INSPECTION_CODE,
  PRINTING_DIRECTION_CODE,
  UNWINDING_DIRECTION_CODE,
} from '@constants/master'

export const getTotalPrintWidth = (step = 0, jobWidth = 0) =>
  Number(jobWidth) * Number(step) + 15

export const getPouchWeight = ({
  gsm = 0,
  job_width = 0,
  job_height = 0,
}: {
  gsm: number
  job_width: number
  repeat: number
  job_height: number
}) =>
  (
    Number(gsm) *
    (Number(job_width) / 1000) *
    (Number(job_height) / 1000)
  ).toFixed(2)
// ((Number(repeat) * Number(job_height)) / 1000)

export const getGSM = (micron = 0, density = 0) =>
  Number((Number(micron) * Number(density)).toFixed(2))

export const getReqQty = ({
  gsm,
  totalGSM,
  outputKg = 0,
  totalWaste,
}: {
  gsm: number
  totalGSM: number
  outputKg: number
  totalWaste: number
}) => {
  const gsmPercentOfTotalGsm = (gsm / totalGSM) * 100
  const outputGrams = outputKg * 1000
  const reqQtyGrams = (gsmPercentOfTotalGsm / 100) * outputGrams
  const reqQtyKg = reqQtyGrams / 1000

  const wasteAddReqQtyKg = reqQtyKg + reqQtyKg * (totalWaste / 100)

  return (wasteAddReqQtyKg || 0).toFixed(2)
}

export const convertKgToMtr = ({
  outputKg = 0,
  totalGSM,
  flimWidth = 0,
}: {
  outputKg: number
  totalGSM: number
  flimWidth: number
}) =>
  Number(
    ((outputKg * Math.pow(10, 6)) / (totalGSM * flimWidth) || 0).toFixed(2)
  )

export const getNoOfPouches = ({
  outputQuantity,
  pouchPerKg,
}: {
  outputQuantity: number
  pouchPerKg: number
}) =>
  (Number(outputQuantity) * Number(Math.ceil(Number(pouchPerKg)))).toFixed(0)

export const getPouchPerKg = (pouchWeight = 1) => Math.ceil(1000 / pouchWeight)

export const getPrintingDirection = ({
  unwindingDirection,
  inspection,
  noOfLayerPass,
}: {
  unwindingDirection: number
  inspection: number
  noOfLayerPass: number
}) => {
  if (noOfLayerPass <= 1) {
    if (unwindingDirection === UNWINDING_DIRECTION_CODE['readable']) {
      if (inspection === INSPECTION_CODE['yes']) {
        return PRINTING_DIRECTION_CODE['un_readable']
      } else {
        return PRINTING_DIRECTION_CODE['readable']
      }
    }

    if (unwindingDirection === UNWINDING_DIRECTION_CODE['un_readable']) {
      if (inspection === INSPECTION_CODE['yes']) {
        return PRINTING_DIRECTION_CODE['readable']
      } else {
        return PRINTING_DIRECTION_CODE['un_readable']
      }
    }
  } else {
    if (unwindingDirection === UNWINDING_DIRECTION_CODE['readable']) {
      if (inspection === INSPECTION_CODE['yes']) {
        return PRINTING_DIRECTION_CODE['readable']
      } else {
        return PRINTING_DIRECTION_CODE['un_readable']
      }
    }

    if (unwindingDirection === UNWINDING_DIRECTION_CODE['un_readable']) {
      if (inspection === INSPECTION_CODE['yes']) {
        return PRINTING_DIRECTION_CODE['un_readable']
      } else {
        return PRINTING_DIRECTION_CODE['readable']
      }
    }
  }
}
