import React, { FC, RefObject } from 'react'
import styles from '../style.module.scss'
import cn from 'classnames'
import { useDFormKeyValue, useDWorkOrder } from '@services/dynamic'
import { useSearchParams } from 'react-router-dom'
import { useOrgInfo } from '@services/org'
import {
  convertKgToMtr,
  getGSM,
  getNoOfPouches,
  getPouchPerKg,
  getPouchWeight,
  getReqQty,
} from '@utils/formula'
import Image from '@components/Image'

const PrintingWorkOrder: FC<{ elRef: RefObject<any> }> = ({ elRef }) => {
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  const { data: org } = useOrgInfo()

  const { data: work, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const { data: job } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const data =
    !!workId && workLoading ? {} : work?.is_completed ? work?.job_data : job

  const jobData = data?.job
  const bomData = data?.bill_of_materials
  const cylinderData = data?.cylinder_details
  const colorData = data?.color
  const processData = data?.process_and_details

  const totalWaste = work?.wastage

  const firstLayer = [...(bomData?.bom || [])]
    ?.filter((f) => f?.data?.code !== 1)
    ?.sort((a, b) => (a?.data?.order || 0) - (b?.data?.order || 0))
    ?.splice(0, 2)?.[0]

  const firstLayerTarget = getReqQty({
    gsm: getGSM(
      firstLayer?.data?.layer?.flim_micron ||
        firstLayer?.data?.layer?.poly_micron,
      firstLayer?.data?.layer?.flim_density ||
        firstLayer?.data?.layer?.poly_density
    ),
    totalGSM: bomData?.total_thickness_gsm,
    outputKg: work?.output_quantity,
    totalWaste,
  })

  const firstLayerTargetMtr = convertKgToMtr({
    flimWidth: cylinderData?.total_printing_width,
    outputKg: Number(firstLayerTarget),
    totalGSM: getGSM(
      firstLayer?.data?.layer?.flim_micron ||
        firstLayer?.data?.layer?.poly_micron,
      firstLayer?.data?.layer?.flim_density ||
        firstLayer?.data?.layer?.poly_density
    ),
  })

  const pouchWeight = Number(
    getPouchWeight({
      gsm: data?.bill_of_materials?.total_thickness_gsm,
      job_height: data?.cylinder_details?.job_height,
      job_width: data?.cylinder_details?.job_width,
      repeat: data?.cylinder_details?.no_of_repeat,
    })
  )

  const outputPlusWastageKg =
    Number(work?.output_quantity) +
    Number(Number(work?.output_quantity * (totalWaste / 100)).toFixed(2))

  return (
    <table
      ref={elRef}
      className={cn(
        styles.jis_table,
        'bg-white w-100 border text-label-small fw-medium'
      )}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={3} rowSpan={2}>
            {!!org?.logo && (
              <Image src={org?.logo} width={48} height={48} alt="Logo" />
            )}
          </td>
          <td
            colSpan={18}
            className="text-heading-small text-center fw-semibold"
          >
            {org?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={12} className="text-title-medium fw-medium text-center">
            PRINTING WORK ORDER
          </td>
          <td colSpan={8} className="text-start">
            No: {work?.work_order_number}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Job No
          </td>
          <td colSpan={6} className="text-start">
            {jobData?.job_code}
          </td>
          <td colSpan={4} className="text-start">
            Inspection
          </td>
          <td colSpan={2} className="text-start">
            {processData?.inspection?.name}
          </td>
          {/* <td colSpan={3} className="text-start"></td> */}
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Job Name
          </td>
          <td colSpan={11} className="text-start">
            {jobData?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={4} className="text-start text-label-small">
            {work?.started_date
              ? new Date(work?.started_date)?.toLocaleDateString('en-IN')
              : '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Party
          </td>
          <td colSpan={11} className="text-start">
            {jobData?.customer_id?.name}
          </td>
          <td colSpan={3} className="text-start">
            PO Check
          </td>
          <td colSpan={3} className="text-start text-label-small"></td>
        </tr>
        <tr>
          <td colSpan={4} className="text-start">
            Structure
          </td>
          <td colSpan={6} className="text-start">
            {firstLayer?.data?.layer?.flim_micron ||
              firstLayer?.data?.layer?.poly_micron}
            μm, {firstLayer?.data?.layer?.name || firstLayer?.data?.layer?.name}
          </td>
          <td colSpan={2} className="text-start">
            Coil
          </td>
          <td colSpan={2} className="text-start">
            {cylinderData?.job_width}mm
          </td>
          <td colSpan={3} className="text-start">
            Rep.Length
          </td>
          <td colSpan={3} className="text-start">
            {cylinderData?.job_height}mm
          </td>
        </tr>
        <tr>
          <td colSpan={4} className="text-start">
            Order Qty.
          </td>
          <td colSpan={5} className="text-start">
            {work?.output_quantity}kg
          </td>
          <td colSpan={3} className="text-start">
            Total GSM
          </td>
          <td colSpan={2} className="text-start">
            {bomData?.total_thickness_gsm}
          </td>
          <td colSpan={3} className="text-start">
            No. Colors
          </td>
          <td colSpan={3} className="text-start">
            {colorData?.colors?.length || 0}
          </td>
        </tr>
        <tr>
          <td colSpan={4} className="text-start">
            Expect Qty.
          </td>
          <td colSpan={3} className="text-start">
            {outputPlusWastageKg}kg
          </td>
          <td colSpan={3} className="text-start">
            Ptg.Direct
          </td>
          <td colSpan={4} className="text-start">
            {/* {bomData?.printing_type?.name} */}
            {processData?.printing_direction?.name}
          </td>
          <td colSpan={3} className="text-start">
            Ink Target
          </td>
          <td colSpan={3} className="text-start">
            {`${getReqQty({
              gsm: 1,
              totalGSM: bomData?.total_thickness_gsm,
              outputKg: work?.output_quantity,
              totalWaste,
            })} Kg`}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Film Width
          </td>
          <td colSpan={4} className="text-start">
            {cylinderData?.total_printing_width}mm
          </td>
          <td colSpan={6} className="text-start">
            Machine Speed
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Target Qty in Mtr
          </td>
          <td colSpan={4} className="text-start">
            {firstLayerTargetMtr}
          </td>
          <td colSpan={6} className="text-start">
            Actual Qty in Mtr
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Target Qty in Kg
          </td>
          <td colSpan={4} className="text-start">
            {`${firstLayerTarget} Kg`}
          </td>
          <td colSpan={6} className="text-start">
            Actual Qty in Kg
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Prod Time + Setting
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Act Time + Setting
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Wastage Print Qty
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Winding Direction
          </td>
          <td colSpan={4} className="text-start">
            {processData?.unwinding_direction?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Wastage Ink in Kg
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Output
          </td>
          <td colSpan={4} className="text-start">
            {processData?.slit_type?.code === 2
              ? getNoOfPouches({
                  outputQuantity: Number(work?.output_quantity),
                  pouchPerKg: getPouchPerKg(pouchWeight),
                })
              : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="bg-grey-200">
            Ink Quantity
          </td>
          <td colSpan={5} className="bg-grey-200">
            Input Quantity
          </td>
          <td colSpan={5} className="bg-grey-200">
            Consumption
          </td>
          <td colSpan={5} className="bg-grey-200">
            Balance Qty.
          </td>
        </tr>
        {[
          ...(colorData?.colors || []),
          { id: 1 },
          { id: 2 },
          { id: 3 },
          { id: 4 },
          { id: 5 },
          { id: 6 },
          { id: 7 },
          { id: 8 },
          { id: 9 },
        ]
          ?.splice(0, 9)
          ?.map((el: any, i: number) => (
            <tr key={el.id} className="text-label-small">
              <td
                colSpan={5}
                style={{
                  height:
                    el?.data?.ink?.name || el?.data?.description
                      ? 'auto'
                      : '20px',
                }}
              >
                {el?.data?.ink?.name || el?.data?.description
                  ? `${i + 1}. ${el?.data?.ink?.name || el?.data?.description}`
                  : ''}
              </td>
              <td colSpan={5}></td>
              <td colSpan={5}></td>
              <td colSpan={5}></td>
            </tr>
          ))}
        {/* <tr
          style={{
            height: '20px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr> */}
        <tr>
          <td colSpan={5}>Ethyl Acitate</td>
          <td colSpan={5}></td>
          <td colSpan={5}></td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={5}>Toluence</td>
          <td colSpan={5}></td>
          <td colSpan={5}></td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={5}>MIBK</td>
          <td colSpan={5}></td>
          <td colSpan={5}></td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={5}>Target Ink GSM</td>
          <td colSpan={5}>1 Gsm</td>
          <td colSpan={5}>Actual Ink GSM</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={5}>Production Incharge</td>
          <td colSpan={5}></td>
          <td colSpan={5}>Operation Sign</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td colSpan={3}>Specification</td>
          <td colSpan={10}></td>
          <td colSpan={3} className="text-end">
            Ink Type
          </td>
          <td colSpan={4}></td>
        </tr>
        <tr
          style={{
            height: '60px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr>
        <tr className="text-label-small">
          <td colSpan={7}>Prepared By</td>
          <td colSpan={7}>Verified By</td>
          <td colSpan={6}>Approved By</td>
        </tr>
      </tbody>
    </table>
  )
}

export default PrintingWorkOrder
