import { createQueryKeys } from '@lukemorales/query-key-factory'

export const dynamicQueries = createQueryKeys('dynamic', {
  form_type: null,
  form: (type) => [type],
  form_key: (form) => [form],
  form_key_value_list: ({ formId, search, customer_id, work_status }) => [
    formId,
    search,
    customer_id,
    work_status,
  ],
  form_key_value: (formId) => [formId],
  work_order_key_value: (formId) => [formId],
  form_option: (id) => [id],
  job_search_list: ({ search, page, page_size }) => [search, page, page_size],
  get_unique_code: null,
  get_form_details_by_code: (code: number) => [code],
  get_static_select_fields: (q: { formId?: string }) => [q],
  get_print: (workId, jobId, type, printCode, orgId) => [
    workId,
    jobId,
    type,
    printCode,
    orgId,
  ],
})
