import { createQueryKeys } from '@lukemorales/query-key-factory'

export const dispatchQueries = createQueryKeys('dispatch', {
  list: (pageSize, search, orgId, dispatch_status) => [
    pageSize,
    search,
    orgId,
    dispatch_status,
  ],
  dispatch_by_id: (code) => [code],
  work_by_id: (search) => [search],
  dispatch_info: (code) => [code],
  dispatch_table: (id, startDate, endDate) => [id, startDate, endDate],
  //   info: (id) => [id],
})
