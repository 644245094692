import React, { FC, RefObject } from 'react'
import styles from '../style.module.scss'
import cn from 'classnames'
import { useOrgInfo } from '@services/org'
import { useDFormKeyValue, useDWorkOrder } from '@services/dynamic'
import { useSearchParams } from 'react-router-dom'
import Image from '@components/Image'
import { convertKgToMtr } from '@utils/formula'

const SlittingWorkOrder: FC<{ elRef: RefObject<any> }> = ({ elRef }) => {
  const [searchParams] = useSearchParams()

  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  const { data: org } = useOrgInfo()

  const { data: work, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const { data: job } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const data =
    !!workId && workLoading ? {} : work?.is_completed ? work?.job_data : job

  const jobData = data?.job
  const bomData = data?.bill_of_materials
  const cylinderData = data?.cylinder_details
  const processData = data?.process_and_details

  return (
    <table
      ref={elRef}
      className={cn(
        styles.jis_table,
        'bg-white w-100 border text-label-small fw-medium'
      )}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={3} rowSpan={2}>
            {!!org?.logo && (
              <Image src={org?.logo} width={48} height={48} alt="Logo" />
            )}
          </td>
          <td
            colSpan={18}
            className="text-heading-small text-center fw-semibold"
          >
            {org?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={20} className="text-title-medium fw-medium text-center">
            SLITTING WORK ORDER
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Job No
          </td>
          <td colSpan={3} className="text-start">
            {jobData?.job_code}
          </td>
          <td colSpan={4} className="text-start">
            {processData?.pouch_type?.name || '-'}
          </td>
          <td colSpan={3} className="text-start">
            F.Width
          </td>
          <td colSpan={2} className="text-start">
            {cylinderData?.total_printing_width || 0}mm
          </td>
          <td colSpan={5} className="text-start text-label-small">
            No: {work?.work_order_number}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Name
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={3} className="text-start">
            {work?.started_date
              ? new Date(work?.started_date)?.toLocaleDateString('en-IN')
              : '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Party
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.customer_id?.name}
          </td>
          <td colSpan={5} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Coil Size
          </td>
          <td colSpan={4} className="text-start">
            {cylinderData?.job_width || 0}mm
          </td>
          <td colSpan={6} className="text-start">
            Slit Type
          </td>
          <td colSpan={4} className="text-start">
            {processData?.slit_type?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Planned Hrs.
          </td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start">
            Bobbin Weight
          </td>
          <td colSpan={4} className="text-start">
            {processData?.bobbin_weight || 0}kg
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Expect Qty.
          </td>
          <td colSpan={4} className="text-start">
            {work?.output_quantity}kg
          </td>

          <td colSpan={6} className="text-start">
            Bobbin Meter
          </td>
          <td colSpan={4} className="text-start">
            {convertKgToMtr({
              flimWidth: Number(cylinderData?.job_width),
              outputKg: processData?.bobbin_weight,
              totalGSM: bomData?.total_thickness_gsm,
            }) || 0}
            mm
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Trim Waste
          </td>
          <td colSpan={4} className="text-start"></td>

          <td colSpan={6} className="text-start">
            Achieved Qty.
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Bobbin OD
          </td>
          <td colSpan={4} className="text-start">
            {processData?.bobbin_od}
          </td>

          <td colSpan={6} className="text-start">
            Slitting Waste
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Winding Direction
          </td>
          <td colSpan={4} className="text-start">
            {processData?.unwinding_direction?.name}
          </td>

          <td colSpan={6} className="text-start">
            Core Type
          </td>
          <td colSpan={4} className="text-start">
            {processData?.core_type?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Core Size
          </td>
          <td colSpan={4} className="text-start">
            {cylinderData?.job_width ? `${cylinderData?.job_width + 5}mm` : 0}
          </td>

          <td colSpan={6} className="text-start">
            Coding PKD
          </td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr>
          <td colSpan={6} className="text-start">
            Total GSM
          </td>
          <td colSpan={4} className="text-start">
            {bomData?.total_thickness_gsm}
          </td>
          <td colSpan={6} className="text-start">
            Pouch Height
          </td>
          <td colSpan={4} className="text-start">
            {processData?.pouch_height || 0}mm
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
          <td colSpan={6} className="text-start"></td>
          <td colSpan={4} className="text-start"></td>
        </tr>
        <tr
          style={{
            height: '60px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr>
        <tr>
          <td colSpan={10} className="text-center">
            Operator
          </td>
          <td colSpan={10} className="text-center">
            Operator
          </td>
        </tr>
        <tr
          style={{
            height: '140px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr>
        <tr>
          <td colSpan={7}>Prepared By</td>
          <td colSpan={7}>Verified By</td>
          <td colSpan={6}>Approved By</td>
        </tr>
      </tbody>
    </table>
  )
}

export default SlittingWorkOrder
