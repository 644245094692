import React, { FC, RefObject } from 'react'
import styles from '../style.module.scss'
import cn from 'classnames'
import { useOrgInfo } from '@services/org'
import { useDFormKeyValue, useDWorkOrder } from '@services/dynamic'
import { useSearchParams } from 'react-router-dom'
import { getNoOfPouches, getPouchPerKg } from '@utils/formula'

const PouchWorkOrder: FC<{ elRef: RefObject<any> }> = ({ elRef }) => {
  const [searchParams] = useSearchParams()

  const jobId = searchParams.get('job') || ''
  const workId = searchParams.get('work') || ''

  const { data: org } = useOrgInfo()

  const { data: work, isLoading: workLoading } = useDWorkOrder(
    {
      enabled: !!workId,
    },
    { formId: workId }
  )

  const { data: job } = useDFormKeyValue(
    {
      enabled: !!jobId,
    },
    { formId: jobId }
  )

  const data =
    !!workId && workLoading ? {} : work?.is_completed ? work?.job_data : job

  const jobData = data?.job
  const processData = data?.process_and_details

  return (
    <table
      ref={elRef}
      className={cn(
        styles.jis_table,
        'bg-white w-100 border text-label-small fw-medium'
      )}
    >
      <colgroup>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
        <col width={'5%'}></col>
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={3} rowSpan={2}></td>
          <td
            colSpan={18}
            className="text-heading-small text-center fw-semibold"
          >
            {org?.name}
          </td>
        </tr>
        <tr>
          <td colSpan={20} className="text-title-medium fw-medium text-center">
            POUCHING WORK ORDER
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Job No
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.job_code}
          </td>
          <td colSpan={5} className="text-start text-label-small">
            No: {work?.work_order_number}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Name
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={3} className="text-start">
            {work?.started_date
              ? new Date(work?.started_date)?.toLocaleDateString('en-IN')
              : '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-start">
            Party
          </td>
          <td colSpan={12} className="text-start">
            {jobData?.customer_id?.name}
          </td>
          <td colSpan={2} className="text-start">
            Date
          </td>
          <td colSpan={3} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Pouching Type
          </td>
          <td colSpan={13} className="text-start">
            {processData?.pouch_type?.name}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Pouch Height
          </td>
          <td colSpan={13} className="text-start">
            {' '}
            {processData?.pouch_height}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Pouch Width
          </td>
          <td colSpan={13} className="text-start">
            {' '}
            {processData?.pouch_width}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Pouch Weight
          </td>
          <td colSpan={13} className="text-start">
            {Number(processData?.pouch_weight)} Gms
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Wastage Qty.
          </td>
          <td colSpan={13} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            No of Pouches
          </td>
          <td colSpan={13} className="text-start">
            {processData?.slit_type?.code === 2
              ? getNoOfPouches({
                  outputQuantity: Number(work?.output_quantity),
                  pouchPerKg: getPouchPerKg(Number(processData?.pouch_weight)),
                })
              : ''}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Seal Width
          </td>
          <td colSpan={13} className="text-start">
            {processData?.seal_width}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Input Qty
          </td>
          <td colSpan={13} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Gusset
          </td>
          <td colSpan={13} className="text-start">
            {processData?.guest_width}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            Punch
          </td>
          <td colSpan={13} className="text-start">
            {processData?.punch?.name}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start">
            No of Holes
          </td>
          <td colSpan={13} className="text-start">
            {processData?.no_of_holes}
          </td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start"></td>
          <td colSpan={13} className="text-start"></td>
        </tr>
        <tr className={styles.blank_row}>
          <td colSpan={7} className="text-start"></td>
          <td colSpan={13} className="text-start"></td>
        </tr>
        <tr
          style={{
            height: '60px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr>
        <tr>
          <td colSpan={10} className="text-center">
            Operator
          </td>
          <td colSpan={10} className="text-center">
            Operator
          </td>
        </tr>
        <tr
          style={{
            height: '140px',
          }}
          className={styles.blank_row}
        >
          <td colSpan={20}></td>
        </tr>
        <tr>
          <td colSpan={7}>Prepared By</td>
          <td colSpan={7}>Verified By</td>
          <td colSpan={6}>Approved By</td>
        </tr>
      </tbody>
    </table>
  )
}

export default PouchWorkOrder
