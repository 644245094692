import { ENV } from '@config/app.config'
import {
  IDForm,
  IDFormKey,
  IDFormKeyValue,
  IDFormKeyValueReq,
  IDFormOption,
  IDFormType,
} from '@contracts/models/dynamic'
import { PaginatedResponse } from '@contracts/models/shared'
import { DELETE, GET, POST, PUT } from '@lib/AxiosClient'

export const getDFormType = (): Promise<IDFormType[]> =>
  GET({ url: 'process/form-type/' })

export const getDForm = (params: { type?: string }): Promise<IDForm[]> =>
  GET({ url: 'process/form/', params })

export const getDFormKey = (params: { form: string }): Promise<IDFormKey[]> =>
  GET({ url: 'process/form-key/', params })

export const getDFormKeyValueList = (
  signal: AbortSignal | undefined,
  params: {
    page: number
    page_size: number
    search?: string
    customer_id?: string
    work_status?: string
  },
  formId: string
): Promise<PaginatedResponse<IDFormKeyValue>> => {
  return GET({
    url: `process/form-key-value-list/${formId}/`,
    signal,
    params: {
      page: params?.page,
      page_size: params?.page_size,
      customer_id: params?.customer_id,
      search: params?.search,
      work_status: params?.work_status,
    },
  })
}

export const postDFormKeyValue = ({
  data,
  formId,
  params,
}: {
  data: IDFormKeyValueReq[]
  formId: string
  params?: { parent_id?: string }
}): Promise<{ message: string }> =>
  POST({
    url: `process/form-key-value-create/${formId}/`,
    data,
    params,
  })

export const getDFormKeyValue = ({
  formId,
}: {
  formId: string
}): Promise<IDFormKeyValue> =>
  GET({
    url: `process/form-key-value/${formId}/`,
  })

export const putDFormKeyValue = ({
  data,
  formId,
}: {
  data: IDFormKeyValueReq[]
  formId: string
}): Promise<IDFormKeyValue> =>
  PUT({
    url: `process/form-key-value/${formId}/`,
    data,
  })

export const getDFormOptionList = ({
  id,
}: {
  id: string
}): Promise<IDFormOption[]> =>
  GET({
    url: `process/get-form-option/${id}/`,
  })

export const postDFormKeyValueValidate = ({
  data,
  params,
}: {
  data: IDFormKeyValueReq[]
  params?: { entity?: string }
}): Promise<{ data: any }> =>
  POST({
    url: 'process/form-key-value-validate/',
    data,
    params,
  })

export const putDUpsertListItem = ({
  data,
  path,
}: {
  data: { id?: string; data: any }
  path: { entityId: string; keyId: string }
}): Promise<any> =>
  PUT({
    url: `process/list-item/${path?.entityId}/${path?.keyId}/`,
    data,
  })

export const deleteDListItem = ({ id }: { id: string }): Promise<any> =>
  DELETE({
    url: `process/list-item/${id}/`,
  })

export const getJobSearchList = (params: {
  search: string
  page: number
  page_size: number
}) =>
  GET({
    url: 'process/job-search/',
    params,
  })

export const getWorkOrder = ({
  formId,
}: {
  formId: string
}): Promise<IDFormKeyValue> =>
  GET({
    url: `process/work/${formId}/`,
  })

export const getUniqueCode = () =>
  GET({
    url: 'process/get-unique-code/',
  })

export const putChangeOrder = (data: { id: string; position: number }) =>
  PUT({
    url: 'process/change-order/',
    data,
  })

export const getFormDetailsByCode = (code: number) =>
  GET({
    url: ENV.INVOICE_API_BASE_URL + `item/form-detail-by-code/${code}/`,
  })

export const getSelectFields = (form?: string) =>
  GET({
    url: `process/static-select-form-key/`,
    params: { form },
  })

export const postCreateSelectOption = ({
  fieldId,
  data,
}: {
  fieldId: string
  data: { name: string }
}) =>
  POST({
    url: `process/static-select-form-key/${fieldId}/`,
    data,
  })

export const putEditSelectOption = ({
  fieldId,
  data,
  code,
}: {
  fieldId: string
  data: { name: string }
  code: number
}) =>
  PUT({
    url: `process/static-select-form-key/${fieldId}/${code}/`,
    data,
  })

export const deleteSelectOption = ({
  fieldId,
  code,
}: {
  fieldId: string
  code: number
}) =>
  DELETE({
    url: `process/static-select-form-key/${fieldId}/${code}/`,
  })

// PRINT
export const getPrintingCard = ({
  workId,
  jobId,
  type,
  printCode,
  orgId,
}: {
  workId: string
  jobId: string
  type: string
  printCode: string
  orgId: string
}): Promise<Blob> => {
  const formData = new FormData()
  formData.append(
    'filePath',
    `${ENV.API_BASE_URL}process/${type}/${workId}/${jobId}/?Organization=${orgId}`
  )
  formData.append('printCode', printCode)

  return POST({
    url: ENV.PRINT_BASE_URL + 'print',
    data: formData,
  })
}
