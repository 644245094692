import { Col, Popconfirm, Row, Segmented } from 'antd'
import { Button, Table, Text } from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { Download, Edit, Print, Trash } from 'yolo-design/icons'
import DispatchBarcode from '../Components/DispatchBarcode'
import { useRef } from 'react'
import DispatchPouchPrint from '../Components/DispatchPouchPrint'
import DispatchWeightPrint from '../Components/DispatchWeightPrint'
import { useReactToPrint } from 'react-to-print'
import { useSearchParams } from 'react-router-dom'

const PouchTable = ({
  isPouch,
  form,
  dispatch_detail,
  deleteDispatchData,
  printFunc,
  printRef,
  printLabelData,
  setPrintLabelData,
  isHavePouchForm,
  setIsHavePouchForm,
  printCount,
}: {
  printRef: any
  printFunc: any
  barcodeFun: any
  barcodeRef: any
  isPouch: any
  form: any
  dispatch_detail: any
  deleteDispatchData: any
  printLabelData: any
  setPrintLabelData: any
  isHavePouchForm: any
  setIsHavePouchForm: any
  printCount: any
}) => {
  const printDispatchRef = useRef(null)

  const printPouchpRef = useRef(null)
  const [searchParams] = useSearchParams()
  const isCompletedDispatch = String(dispatch_detail?.dispatch_status) === '3'

  const isView = searchParams.get('view')

  const handlePrintDispatchDetail = useReactToPrint({
    // onAfterPrint: () => setBillData(undefined),
    pageStyle: '@page { size: 210mm 297mm; margin: 4mm }',
    content: () => printDispatchRef.current,
  })
  const handlePrintPouchDispatchDetail = useReactToPrint({
    // onAfterPrint: () => setBillData(undefined),
    pageStyle: '@page { size: 210mm 297mm; margin: 4mm }',
    content: () => printPouchpRef.current,
  })

  const columns: any = [
    {
      title: '',
      dataIndex: '',
      align: 'center',
      render: (_: any, rec: any) => {
        return (
          <div>
            <Popconfirm
              placement="leftTop"
              style={{ width: '200px' }}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{
                loading: deleteDispatchData?.isLoading,
                style: {
                  borderColor: Color?.indicators?.error,
                  backgroundColor: 'transparent',
                  color: Color?.indicators?.error,
                },
              }}
              title="Are you sure to delete this entry?"
              onConfirm={() => {
                deleteDispatchData?.mutate({ id: rec?.id })
              }}
              icon={false}
            >
              <Trash weight="thin" color={Color?.indicators?.error} />
            </Popconfirm>
          </div>
        )
      },
    },
    {
      title: 'SL NO',
      dataIndex: 'serial_no',
      align: 'center',
    },
    ...(isPouch
      ? [
          {
            title: 'BUNDLE NO',
            dataIndex: 'bundle_no',
            align: 'center',
          },
          {
            title: 'PKT NO FROM',
            dataIndex: 'packet_no_from',
            align: 'center',
          },
          {
            title: 'PKT NO TO',
            dataIndex: 'packet_no_to',
            align: 'center',
          },
          {
            title: 'PACKETS',
            dataIndex: 'packet_per_bundle',
            align: 'center',
          },
          {
            title: 'POUCH PER PKT',
            dataIndex: 'pouch_per_packet',
            align: 'center',
            render: (text: any, rec: any) => {
              return (
                <>
                  <div>
                    {rec?.extra_pouch ? `${text} + ${rec?.extra_pouch}` : text}
                  </div>
                </>
              )
            },
          },
          {
            title: 'TOTAL POUCH',
            dataIndex: 'total_pouch',
            align: 'center',
          },
        ]
      : []),

    {
      title: 'GROSS WEGHT',
      dataIndex: 'gross_weight',
      align: 'center',
    },
    {
      title: 'TARE WEGHT',
      dataIndex: 'tare_weight',
      align: 'center',
    },
    {
      title: 'NET WEGHT',
      dataIndex: 'net_weight',
      align: 'center',
    },
    ...(String(dispatch_detail?.dispatch_status) === '3'
      ? []
      : [
          {
            title: 'ACTION',
            dataIndex: 'action',
            render: (_: any, rec: any) => {
              return (
                <>
                  <div className="d-flex gap-2">
                    <Print
                      onClick={() => {
                        setPrintLabelData(rec),
                          setTimeout(() => {
                            printFunc()
                            setPrintLabelData(null)
                          }, 0)
                      }}
                      weight="thin"
                      color={Color?.blue?.just_blue}
                    />
                    <Popconfirm
                      placement="leftTop"
                      style={{ width: '200px' }}
                      okText="Edit"
                      cancelText="Cancel"
                      title="Are you sure to edit this entry?"
                      onConfirm={() => {
                        form.setFieldsValue({
                          sl_no: rec?.serial_no,
                          tar: rec?.tare_weight,
                          gross: rec?.gross_weight,
                          pouch_to_packet: rec?.pouch_per_packet,
                          packet_to_bundle: rec?.packet_per_bundle,
                          extra_to_pouch: rec?.extra_pouch,
                          packet_from: rec?.packet_no_from,
                          packet_to: rec?.packet_no_to,
                        })
                      }}
                      icon={false}
                    >
                      <Edit weight="thin" />
                    </Popconfirm>
                  </div>
                </>
              )
            },
          },
        ]),
  ]
  const totalWeightCalc = (ell: any) => {
    return dispatch_detail?.dispatch_items
      ?.map((item: any) => parseFloat(item?.[ell]))
      ?.reduce((acc: any, cur: any) => acc + cur, 0)
  }
  const totalGross = Number(totalWeightCalc('gross_weight')).toFixed(3) || ''
  const totalTare = Number(totalWeightCalc('tare_weight')).toFixed(3) || ''
  const totalNet = Number(totalWeightCalc('net_weight')).toFixed(3) || ''

  return (
    <div>
      <Row>
        <Col span={isHavePouchForm ? 24 : 12}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <Text
                category="title"
                weight="bold"
                value={`Dispatch List (${
                  dispatch_detail?.dispatch_items?.length || 0
                })`}
              />

              <>
                <div style={{ width: '300px' }}>
                  <Segmented
                    onChange={(e) => {
                      setIsHavePouchForm(e === '1' ? true : false)
                    }}
                    disabled={
                      dispatch_detail?.dispatch_items?.length
                        ? true
                        : false || isCompletedDispatch
                    }
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: Color?.gray_shades?.gray_200,
                    }}
                    size="middle"
                    value={isHavePouchForm ? '1' : '2'}
                    defaultValue={'2'}
                    options={[
                      { label: 'Packing Form', value: '2' },
                      { label: 'Pouching Form', value: '1' },
                    ]}
                  />
                </div>
              </>
            </div>

            {dispatch_detail?.dispatch_status && isView === 'view' ? (
              <>
                <div className="d-flex gap-2">
                  <Button
                    size="extra_small"
                    icon={Download}
                    label="Packing List"
                    type="solid"
                    antdButtonProps={{
                      onClick: () => handlePrintDispatchDetail(),
                    }}
                  />
                  {dispatch_detail?.enable_packing ? (
                    <>
                      <Button
                        size="extra_small"
                        icon={Download}
                        label="Pouching List"
                        type="solid"
                        antdButtonProps={{
                          onClick: handlePrintPouchDispatchDetail,
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="mt-12">
            <Table
              pagination={false}
              style={{ height: 'calc(100vh - 800px)' }}
              scroll={{ y: 'calc(100vh - 250px)' }}
              columns={columns}
              dataSource={dispatch_detail?.dispatch_items}
              summary={() => {
                return (
                  <tr
                    style={{
                      position: 'sticky',
                      bottom: 0,
                      backgroundColor: 'white',
                      zIndex: 10,
                    }}
                  >
                    <td></td>
                    <td className="text-center">
                      <span className="text-body-medium fw-bold">Total</span>
                    </td>
                    {isPouch ? (
                      <>
                        <td></td>
                        <td></td>

                        <td></td>

                        <td></td>

                        <td></td>
                        <td></td>
                      </>
                    ) : (
                      <></>
                    )}
                    {dispatch_detail?.dispatch_items?.length ? (
                      <>
                        <td className="text-center">
                          <span className="text-body-medium fw-bold">
                            {totalGross ? totalGross : ''}
                          </span>
                        </td>

                        <td className="text-center">
                          <span className="text-body-medium fw-bold">
                            {totalTare || 0}
                          </span>
                        </td>

                        <td className="text-center">
                          <span className="text-body-medium fw-bold">
                            {totalNet || 0}
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>

                        <td></td>
                      </>
                    )}

                    <td></td>
                  </tr>
                )
              }}
            />
          </div>
          <div className="d-none">
            <div ref={printRef}>
              {Array.from({ length: printCount }, (_, index) => (
                <div key={index} className="barcode-page">
                  <DispatchBarcode
                    printLabelData={printLabelData}
                    dispatchDetail={dispatch_detail}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="d-none">
            {isView === 'view' ? (
              <>
                <DispatchPouchPrint
                  dispatchData={dispatch_detail}
                  refDetail={printPouchpRef}
                />
                <DispatchWeightPrint
                  dispatchData={dispatch_detail}
                  refDetail={printDispatchRef}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PouchTable
